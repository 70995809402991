import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';

    
export default function Home() {
    return (

		<Grid container spacing={5} sx={{paddingInline: '2rem', paddingTop:'1rem', paddingBottom: '3rem', backgroundColor: '#efdfbb', marginTop:0}}>
			<Grid item xs={12}>
				<Card sx={{backgroundColor: '#efdfbb', border: "none", boxShadow: "none", textAlign: 'center', paddingInline: '6rem' }}>
					<CardMedia
						//sx={{ height: 700, width: '80%', margin: 'auto'}}
						sx={{ height: 700}}
						image={`${require('./../images/P11.JPG')}`}
					/>
					<CardContent sx={{padding: '0.5rem'}}>
						<Typography align='justify' variant="h6" color="text.primary" >
						Los Bosques de Huiro flotador o Macrocystis pyrífera están presentes a lo largo de la costa de 
						Chile y son ecosistemas fundamentales para  la Biodiversidad costera y múltiples servicios 
						ecosistémicos, entre los que destacan la captación de CO2, la protección de la costa y la 
						oxigenación del agua, pero son además claves para sostener las pesquerías artesanales y la 
						economía de las comunidades costeras. Es por esto que actualmente son consideradas como 
						soluciones basadas en la naturaleza frente al cambio climático.
						</Typography>
					</CardContent>
					<CardContent sx={{padding: '0.5rem'}}>
						<Typography align='justify' variant="h6" color="text.primary" >
						A pesar de esta importancia, estos bosques se ven amenazados por la sobreexplotación, el cambio 
						climático y la contaminación de la costa. Es por esto que es fundamental protegerlos y 
						restaurarlos en los lugares en donde han desaparecido.
						</Typography>
					</CardContent>
					<CardContent sx={{padding: '0.5rem'}}>
						<Typography align='justify' variant="h6" color="text.primary" >
						Para aportar en las metodologías de restauración comunitaria de estos ecosistemas, se llevó a 
						cabo un proyecto piloto llamado “Co-diseño de Soluciones Basadas en la Naturaleza como 
						adaptación al cambio climático en zonas costeras" en la localidad costera de Coliumo, comuna de 
						Tomé, región del Biobío, Chile. Esta iniciativa realizada entre marzo de 2022 y octubre de 2023, 
						fue financiada por la Fundación David and Lucile Packard y ejecutada por la Facultad de Ciencias 
						Ambientales de la Universidad de Concepción en conjunto con dos coinvestigadores de Coliumo. 

						</Typography>
					</CardContent>
					
				</Card>
			</Grid>
			<Grid item xs={12} >
				<Card sx={{backgroundColor: '#efdfbb', border: "none", boxShadow: "none", textAlign: 'center', paddingInline: '6rem' }}>
					<CardMedia
						sx={{ height: 700}}
						image={`${require('./../images/P7.jpg')}`}
					/>
				</Card>
			</Grid>

			<Grid item xs={12} >
				<Card sx={{backgroundColor: '#efdfbb', border: "none", boxShadow: "none", textAlign: 'center', paddingInline: '6rem' }}>
					<CardMedia
						sx={{ height: 700}}
						image={`${require('./../images/P21.jpg')}`}
					/>
					
				</Card>
			</Grid>
			
			<Grid item xs={12}>
				<Card sx={{backgroundColor: '#efdfbb', border: "none", boxShadow: "none", textAlign: 'center', paddingInline: '6rem' }}>
					<CardContent sx={{padding: '0.5rem'}}>
						<Typography align='justify' variant="h4" color="text.primary">
							GEOPORTAL DE COLIUMO
						</Typography>
						<Typography align='justify' variant="h6" color="text.primary">
							En esta plataforma se presenta un Geoportal como una herramienta de visualización de los 
							resultados obtenidos mediante un mapeo comunitario realizado en el marco de este proyecto. 
							A través de este geoportal, es posible visualizar diferentes aspectos del territorio, sus 
							principales problemáticas, diferentes usos, zonas de protección y lugares donde existen 
							macroalgas. Además se incluyeron capas de planificación territorial (Planes reguladores, 
							zonificacion costera, etc), zonas de inundación y de evacuasión ante Tsunamis, así como la 
							ubicación de conseciones marinas y las Areas de Manejo y Explotación de recursos bentónicos 
							(AMERB). De esta forma es posible ver las interacciones entre estos elementos desde una 
							mirada del ordenamiento territorial y cómo se relacionan con los bosques submarinos, los 
							recursos marinos en general y los usos mediante los que la comunidad se relaciona con estos 
							ecosistemas. 
						</Typography>
					</CardContent>

					<CardContent sx={{padding: '0.5rem'}}>
						<Typography align='justify' variant="h6" color="text.primary">
							Este geoportal está disponible para el uso de la comunidad en general y por todas aquellas 
							personas que estén interesadas en la restauración comunitaria y la protección de bosques 
							submarinos.
						</Typography>
					</CardContent>
					
				</Card>
			</Grid>
		</Grid>
    );
}      
