// estilos a los componenetes de la barra de navegacion
import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

export const Nav = styled.nav`
    background: #a97861;
    height: 8rem;
    display: flex;
	top: 0;
    width: 100%;
`;

export const NavLink = styled(Link)`
    color: #fefefe;
    display: flex;
	text-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    font-size: 1.8rem;
    padding-left: 2rem;
	padding-top: 2rem
	padding-bottom: 2rem
    cursor: pointer;
    &.active {
        color: #efdfbb;
    }
`;


export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 2rem;
        cursor: pointer;
		padding-top: 2rem
    }
`;

export const Bars = styled(FaBars)`
    display: none;
    color: #fefefe;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 2rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
	background: #a97861;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;