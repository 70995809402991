//importacion de .Geojson en data donde hay que cambiar la extensión a json para poder visualizar. importando tambien las capas
// esteros y canales, y las otrasm tambien el marker que es el punto en cual se despliegan las imagenes//
import React from 'react';
import { MapContainer, TileLayer, GeoJSON, LayersControl } from 'react-leaflet';
import { Grid } from '@mui/material';

// estilos de mapa que luego en el app.css los sobre escribimos//
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

//importamos los datas osea las capas en formato Geojson pero convertido en json
import AAA from '../data/AAA.json'
import AMERB from '../data/AMERB.json'
import AREA_EVACUACION from '../data/AREA_EVACUACION.json'
import CALETAS from '../data/CALETAS.json'
import CITSU_SHOA_TOME from '../data/CITSU_SHOA_TOME.json'
import CONCESIONES_FINAL from '../data/CONCESIONES_FINAL.json'
import EDIFICACIONES from '../data/EDIFICACIONES.json'
import MAPEO_BUZO_AREAL from '../data/MAPEO_BUZO_AREAL.json'
import MAPEO_BUZO_PUNTUAL from '../data/MAPEO_BUZO_PUNTUAL.json'
import TALLERES from '../data/TALLERES.json'

// import blue from '../images/blue.png';
import green from '../images/green.png';
import purple from '../images/purple.png';
// import yellow from '../images/yellow.png';
import red from '../images/red.png';
import greenCircle from '../images/green_circle.png';
import redCircle from '../images/red_circle.png';
import blueCircle from '../images/blue_circle.png';
import pinkCircle from '../images/pink_circle.png';
import orangeCircle from '../images/orange_circle.png';


const center = [-36.539749, -72.945023];

function getMapIcon(iconUrl) {
    return L.icon({
        iconUrl: iconUrl,
        iconSize: [26, 26],
        popupAnchor: [0, -15],
    });
}

function pointToLayerClosure(aux) {
	return function pointToLayer(feature, latlng) {
		return L.marker(latlng, {icon: getMapIcon(aux)});
	}
}

function createDinamicPointLayer(subgrupo) {
    return function pointToLayer(feature, latlng) {
        const GRUPO_PM = feature.properties && feature.properties.GRUPO_PM;
        // Filtra los puntos según el subgrupo
        if (GRUPO_PM === subgrupo) {
            let iconUrl;
            // Asigna el icono correspondiente
            switch (subgrupo) {
                case "algas":
                    iconUrl = greenCircle;
                    break;
                case "bosque referencia":
                    iconUrl = pinkCircle;
                    break;
                case "problemas":
                    iconUrl = redCircle;
                    break;
                case "restauración huiro":
                    iconUrl = blueCircle;
                    break;
                case "usos":
                    iconUrl = orangeCircle;
                    break;
                default:
                    iconUrl = purple;
            }
            const icon = getMapIcon(iconUrl);
            return L.marker(latlng, { icon });
        } else {
            // Retorna null si el punto no pertenece al subgrupo, para que no se muestre en la capa
            return null;
        }
    };
}

function onEachFeatureClosure(list) {
    return function onEachFeature(feature, layer) {
        let final = "<div class='custom-popup'>";
        
        if (Array.isArray(list)) {
            // Handle list as an array
            for (let i = 0; i < list.length; i++) {
                if (feature.properties && feature.properties[list[i]]) {
                    final += `${feature.properties[list[i]].toString()}<br>`;
                }
            }
        } else if (typeof list === 'object') {
            // Handle list as an object
            for (const key in list) {
                if (feature.properties && feature.properties[key]) {
                    final += `${list[key]}: ${feature.properties[key].toString()}<br>`;
                }
            }
        }
        
        final += "</div>";
        layer.bindPopup(final);
    };
}


export default function MapViewer() {
	const { BaseLayer } = LayersControl;

    return (
		<Grid container sx={{paddingInline: '2rem', paddingTop:'3rem', paddingBottom: '3rem', backgroundColor: '#efdfbb'}}>
			<Grid item xs={12}>
				<MapContainer center={center} zoom={14.1} style={{ height: '40vw', width: '90vw'}}>
					<LayersControl position="topright">

						<BaseLayer checked name="Satélite">
							<TileLayer
							url="https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=DuJSUkEwKHOAsLwiXsvB"
							attribution="&copy; <a href=&quot;https://api.maptiler.com/maps/satellite/tiles.json?key=DuJSUkEwKHOAsLwiXsvB&quot;>Maptiler</a> contributors"
							/>
						</BaseLayer>

						<BaseLayer name="OpenStreetMap" >
							<TileLayer
							url="https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=DuJSUkEwKHOAsLwiXsvB"
							attribution="&copy; <a href=&quot;https://api.maptiler.com/maps/openstreetmap/tiles.json?key=DuJSUkEwKHOAsLwiXsvB&quot;>Maptiler &copy; OpenStreetMap</a> contributors"
							/>
						</BaseLayer>

						<LayersControl.Overlay name="AAA" >
							<GeoJSON data={AAA} onEachFeature={onEachFeatureClosure(["REP_SUBP_4"])} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="ACUICULTURA" >	
							<GeoJSON data={CONCESIONES_FINAL} onEachFeature={onEachFeatureClosure({TITULAR: "Nombre",ESPECIES: "Recursos",SUPERFICIE: "Tamaño" })} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="AMERB" >
							<GeoJSON data={AMERB} onEachFeature={onEachFeatureClosure({REP_SUBP_3: "Nombre",REP_SUBP_7: "Sindicato",REP_SUB_18: "Recursos",REP_SUB_21: "Vigencia"})} pathOptions={{color: "orange", fillOpacity: 0}} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="ÁREA EVACUACIÓN" >	
							<GeoJSON data={AREA_EVACUACION} onEachFeature={onEachFeatureClosure(["sector"])} pathOptions={{fillOpacity: 0.2, color: "#ffffb3"}} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="CALETAS" >	
							<GeoJSON data={CALETAS} onEachFeature={onEachFeatureClosure(["REP_SUBP_1"])} pointToLayer={pointToLayerClosure(green)} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="CARTAS DE INUNDACIÓN POR TSUNAMI SHOA (CITSU)">	
							<GeoJSON data={CITSU_SHOA_TOME} onEachFeature={onEachFeatureClosure(["CLASE"])} pathOptions={{color: "pink", fillOpacity: 0}} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="CONCESIONES" >	
							<GeoJSON data={CONCESIONES_FINAL} onEachFeature={onEachFeatureClosure(["TOPONIMIO"])} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="EDIFICACIONES" >
							<GeoJSON data={EDIFICACIONES} pathOptions={{fillOpacity: 0.2, color: "#80b1d3"}}/>
						</LayersControl.Overlay>

						<LayersControl.Overlay name="MAPEO SUBMARINO AREAL" >
							<GeoJSON data={MAPEO_BUZO_AREAL} onEachFeature={onEachFeatureClosure(["NAME"])} pathOptions={{fillOpacity: 0.2, color: "#d9d9d9"}}/>
						</LayersControl.Overlay>

						<LayersControl.Overlay name="MAPEO SUBMARINO PUNTUAL" >	
							<GeoJSON data={MAPEO_BUZO_PUNTUAL} onEachFeature={onEachFeatureClosure(["NAME"])} pointToLayer={pointToLayerClosure(red)}/>
						</LayersControl.Overlay>

						<LayersControl.Overlay name="TALLER IDENTIFICACIÓN DE ALGAS" >
							<GeoJSON data={TALLERES} onEachFeature={onEachFeatureClosure(["COMEN_PM"])} pointToLayer={createDinamicPointLayer("algas")} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="TALLER IDENTIFICACIÓN DE BOSQUE DE REFERENCIA" >
							<GeoJSON data={TALLERES} onEachFeature={onEachFeatureClosure(["COMEN_PM"])} pointToLayer={createDinamicPointLayer("bosque referencia")} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="TALLER IDENTIFICACIÓN DE PROBLEMAS" >
							<GeoJSON data={TALLERES} onEachFeature={onEachFeatureClosure(["COMEN_PM"])} pointToLayer={createDinamicPointLayer("problemas")} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="TALLER IDENTIFICACIÓN DE RESTAURACIÓN" >
							<GeoJSON data={TALLERES} onEachFeature={onEachFeatureClosure(["COMEN_PM"])} pointToLayer={createDinamicPointLayer("restauración huiro")} />
						</LayersControl.Overlay>

						<LayersControl.Overlay name="TALLER IDENTIFICACIÓN DE USOS" >
							<GeoJSON data={TALLERES} onEachFeature={onEachFeatureClosure(["COMEN_PM"])} pointToLayer={createDinamicPointLayer("usos")} />
						</LayersControl.Overlay>
							
					</LayersControl>
				</MapContainer>
			</Grid>
			
		</Grid>

        
    );
};
