import React from 'react';
import {ImageList, ImageListItem, Grid, CardContent, Typography, Card } from '@mui/material';

function srcset(image, size, rows = 1, cols = 1) {
	return {
	  src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
	  srcSet: `${image}?w=${size * cols}&h=${
		size * rows
		}&fit=crop&auto=format&dpr=2 2x`,
	};
}

export default function Gallery() {
	return (
		<Grid item xs={12}>
			<ImageList 
				sx={{height: 1, padding: '1rem', backgroundColor: '#efdfbb'}}
				variant="quilted"
				cols={4}
				rowHeight={300}
				>
				{itemData.map((item) => (
					<ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
					<img
						{...srcset(item.img, 300, item.rows, item.cols)}
						alt={item.title}
						loading="lazy"
					/>
					</ImageListItem>
				))}
				<Card sx={{backgroundColor: '#efdfbb', border: "none", boxShadow: "none", textAlign: 'center'}}>
					<CardContent sx={{ paddingTop: '2rem'}}>
						<Typography align='justify' variant="h6" color="text.primary">
							Fotos submarinas tomadas por Mauricio Altamirano.
						</Typography>
						<Typography align='justify' variant="h6" color="text.primary">
							Otras fotos: Luisa Saavedra, Jorge Espiñoza. 
						</Typography>
					</CardContent>
				</Card>
			</ImageList>
		</Grid>
	)
	
}

const itemData = [
	{
        img: `${require('./../images/P1.JPG')}`,
		title: 'P1',
		rows: 4,
		cols: 2
    },
    {
        img: `${require('./../images/P2.JPG')}`,
		title: 'P2',
    },
    {
        img: `${require('./../images/P3.JPG')}`,
		title: 'P3',
    },
    {
        img: `${require('./../images/P4.JPG')}`,
		title: 'P4',
    },
    {
        img: `${require('./../images/P5.jpg')}`,
		title: 'P5',
    },
	{
        img: `${require('./../images/P20.jpg')}`,
		title: 'P20',
		rows: 4,
		cols: 2
    },
    {
        img: `${require('./../images/P6.jpg')}`,
		title: 'P6',
    },
	{
        img: `${require('./../images/P30.png')}`,
		title: 'P30',
    },
    {
        img: `${require('./../images/P8.jpg')}`,
		title: 'P8',
		rows: 4,
		cols: 2
    },
    {
        img: `${require('./../images/P7.jpg')}`,
		title: 'P7',
    },
    {
        img: `${require('./../images/P9.JPG')}`,
		title: 'P9',
    },
	{
        img: `${require('./../images/P17.jpg')}`,
		title: 'P17',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P10.JPG')}`,
		title: 'P10',
    },
    {
        img: `${require('./../images/P11.JPG')}`,
		title: 'P11',
    },
	{
        img: `${require('./../images/P16.jpg')}`,
		title: 'P16',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P12.JPG')}`,
		title: 'P12',
    },
	{
        img: `${require('./../images/P13.jpg')}`,
		title: 'P13',
    },
	{
        img: `${require('./../images/P19.jpg')}`,
		title: 'P19',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P27.JPG')}`,
		title: 'P27',
    },
	{
        img: `${require('./../images/P29.jpg')}`,
		title: 'P29',
    },
    {
        img: `${require('./../images/P23.jpg')}`,
		title: 'P23',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P14.jpg')}`,
		title: 'P14',
    },
	{
        img: `${require('./../images/P15.jpg')}`,
		title: 'P15',
    },
    {
        img: `${require('./../images/P24.jpg')}`,
		title: 'P24',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P18.JPG')}`,
		title: 'P18',
    },
	{
        img: `${require('./../images/P22.jpg')}`,
		title: 'P22',
    },
	{
        img: `${require('./../images/P25.jpg')}`,
		title: 'P25',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P26.jpg')}`,
		title: 'P26',
		rows: 4,
		cols: 2
    },
	{
        img: `${require('./../images/P28.JPG')}`,
		title: 'P28',
    },
	{
        img: `${require('./../images/P21.jpg')}`,
		title: 'P21',
    }
];