import * as React from 'react';
import { Nav, NavLink, Bars, NavMenu, MobileIcon } from './NavBarElements';
import {  Typography } from '@mui/material';
import { BiHome, BiMapAlt, BiSolidCamera } from 'react-icons/bi';

export default function NavBar({ toggle }) {
    return (
        <Nav>
            <MobileIcon onClick={toggle}>
                <Bars />
            </MobileIcon>
            <NavMenu>
                <NavLink to="/" >
					<BiHome />
                    Inicio
                </NavLink>
                <NavLink to="/mapa" >
					<BiMapAlt />
                    Visualizador de Mapa
				</NavLink>
				<NavLink to="/galeria" style={{marginRight: "6rem"}}>
					<BiSolidCamera />
                    Galería de Fotos
				</NavLink>

				<Typography variant="h5" color="#fefefe" style={{marginRight: "20rem", alignItems: "center", fontSize: "1.8rem"}} >
				Restaurando los Bosques de Huiro Flotador en la costa del Biobío: Caso piloto de Coliumo
				</Typography>
            </NavMenu>
			
        </Nav>
    );
}